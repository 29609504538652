$primary: #39B597;
$warning: #F8D645;
$danger: #E95688;
$success: #39B597;

@import 'node_modules/bootstrap/scss/bootstrap.scss';
@import '~alertifyjs/build/css/alertify.css';
@import '~alertifyjs/build/css/themes/bootstrap.css';
@import '~datatables.net-dt/css/jquery.dataTables.css';
@import '~flatpickr/dist/themes/airbnb.css';


div.dataTables_wrapper div.dataTables_processing {
	top: 0;
	background-color: rgba(255,255,255,.7);
	border: 1px solid;
	left: 0;
	width: 100%;
	margin: 0;
	height: 500%;
	min-height: 100px;
	padding-top: 100px;
	font-weight: bold;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.current, 
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
	color: white !important;
	background: $primary !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
	color: $primary !important;
}

span.flatpickr-day.selected, span.flatpickr-day.selected:hover {
	background-color: $primary;
	border-color: $primary;
}